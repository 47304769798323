<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'base-input-action-v2',
  components: {
    BaseInput,
    BaseIcon,
    BaseLoader,
    BaseButton,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<template>
  <base-input
    v-bind="$attrs"
    ref="input"
    :loading="false"
    :disabled="$attrs.disabled || $attrs.loading"
    @unmasked="$emit('unmasked', $event)"
    @input="$emit('input', $event)"
    @keyup="$emit('keyup', $event)"
  >
    <template #inner-filter>
      <slot name="inner-filter">
        <base-button
          class="flex !h-full !min-w-0 !rounded-none !rounded-r-sm text-white"
          :class="{
            'pointer-events-none opacity-80': $attrs.loading,
          }"
          @click="$emit('click')"
        >
          <base-loader v-if="$attrs.loading" size="xs" />
          <base-icon v-else-if="icon" :svg="icon" :size="iconSize" />
          <slot name="button-text" />
        </base-button>
      </slot>
    </template>

    <template #disclaimer>
      <slot name="disclaimer" />
    </template>

    <template #error>
      <slot name="error" />
    </template>
  </base-input>
</template>
